@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    font-family: "Poppins", sans-serif;
  }
}

/* .stars{
  position: relative;
  min-width: 200px;
  display: inline-flex;
}

.star{
  position: absolute;
  display: inline-flex;
  mask: url(../src/assets/img/Star_active.png) no-repeat center left;
}

.star:not(.placeholder){
  z-index: 2;
  background-color: yellow;
}

.star.placeholder{
  z-index: 1;
  background-color: #b0b0b0;
} */

.star{
  mask: url(../src/assets/img/Star_active.png) no-repeat center left;
}
